import segmentTracking from '@utilities/segmentTracking';
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState('customer', ['cdata']),
  },

  methods: {
    mix_trackEvent(eventName, properties = {}) {
      return segmentTracking.trackSegmentEvent(eventName, properties);
    },

    mix_trackExperimentViewed(experimentName) {
      if (this.experiments[experimentName]) {
        this.mix_trackEvent('Experiment Viewed', { experimentName });
      }
    }
  }
};
