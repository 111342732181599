<template lang="pug">
  .rewards-box.xs-f-small.max-at-tweak(v-if="membership || creditBalance")
    .your-rewards-wrap(v-if="membership")
      .progress-bar
        .progress-bar-inner(:style="{width: progressWidth}")
      p.rewards-text.xs-f-small.md-f-medium.max-at-tweak
        | Your Rewards&nbsp;
        span.rewards.bold ${{ membership.total_credit }}
    
    .your-rewards-wrap(v-else-if="creditBalance")
      p.rewards-text.xs-f-small.md-f-medium.max-at-tweak
        | Your Credits&nbsp;
        span.rewards.bold ${{creditBalance}}

    .spend-threshold(v-if="membership && membership.credit_reward && membership.spend_target")
      p.spend-threshold-text.xs-f-xxsmall.md-f-xsmall.max-at-tweak.text-color-1
        | Spend ${{ spendForReward }} to earn your ${{ membership.credit_reward }} reward&nbsp;

</template>

<script>
  import { mapState } from 'vuex';
  import vueCustomerSvc from '@services/vueCustomerSvc';

  export default {
    name: "RewardsBox",

    data() {
      return {
        membership: null,
        progressWidth: 0
      };
    },

    computed: {
      ...mapState('customer', ['cdata']),

      creditBalance() {
        return this.cdata.creditBalance;
      },

      membershipProgress() {
        let progress = 0;

        if (this.membership) {
          progress = ((this.membership.total_spend % this.membership.spend_target) / this.membership.spend_target * 100).toFixed(2);
        }

        return progress;
      },

      spendForReward() {
        let spend = 0;

        if (this.membership) {
          return (this.membership.spend_target - this.membershipProgress).toFixed(2);
        }

        return spend;
      }
    },

    async mounted() {
      vueCustomerSvc.getMembershipWithLoyaltyData().then(res => {
        if (res && res.data && res.data.membership) {
          this.membership = res.data.membership;
          setTimeout(() => {
            this.progressWidth = this.membershipProgress + "%";
          }, 300);
        }
      });
    }
  };
</script>

<style scoped lang="stylus">
  .rewards-box
    padding 0.5em 5%
    
    .your-rewards-wrap
      position relative
      padding 1.25em 5%
      background-color ui-color-3
      border-radius 0.5em
      overflow hidden
      
      .progress-bar
        display block
        position absolute
        top 0
        left 0
        height 8px
        width 100%
        background-color ui-color-5
        
        .progress-bar-inner
          position absolute
          top 0
          left 0
          height 8px
          width 0
          transition(width 1000ms ease-in-out)
          background-color cta-color-1
          border-radius 0 4px 4px 0
          
      .rewards-text
        padding-top 6px
        
        .rewards
          float right
          
    .spend-threshold
      .spend-threshold-text
        padding 0.5em 2%
        text-align left

    
</style>
