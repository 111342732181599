const state = {
  showButtonCorner: false,
  firstClickAction: true,
  isScriptLoading: false,
  isButtonClicked: false,
  loadingMode: null,
  noLoad: false,
  // Progress bar
  loadingInterval: null,
  currentLoadingProgress: 0,
  displayLoadingProgress: 0,
  loadingProgressStep: 0.3,
  chatAvailable: false
};

const getters = {};
const actions = {};

const mutations = {

  setShowButtonCorner(state, val) {
    state.showButtonCorner = val;
  },

  setFirstClickAction(state, val) {
    state.firstClickAction = val;
  },

  setIsScriptLoading(state, val) {
    state.isScriptLoading = val;
  },

  setIsButtonClicked(state, val) {
    state.isButtonClicked = val;
  },

  setLoadingMode(state, val) {
    state.loadingMode = val;
  },

  setNoLoad(state, val) {
    state.noLoad = val;
  },

  setLoadingInterval(state, val) {
    state.loadingInterval = val;
  },

  setCurrentLoadingProgress(state, val) {
    state.currentLoadingProgress = val;
  },

  setDisplayLoadingProgress(state, val) {
    state.displayLoadingProgress = val;
  },

  setLoadingProgressStep(state, val) {
    state.loadingProgressStep = val;
  },

  setChatAvailable(state, val) {
    state.chatAvailable = val;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
