import mrApi from './mrApi';

export const COLOR_ADVISOR_SINGLE_PAGE_EXPERIMENT = 'Color Advisor Single Page';

export default {
  isQuizComplete,
  createColorSolutionZendeskTicket,
  getQuizFlow,
  getCustomerAnswers,
  getHairToneOptions,
  persistCustomerAnswers,
  createZendeskTicket,
  dequeueZendeskTicket,
  updateSubscriptionFrequency,
  saveNPS,
  trackQuizEvent,
  saveRecommendations,
  assignCustomerPromo,
  isAnyAnswerRecordedForQuiz,
  COLOR_ADVISOR_SINGLE_PAGE_EXPERIMENT
};

export function isColorAdvisorSinglePageExperiment() {
  return window.experiments && window.experiments[COLOR_ADVISOR_SINGLE_PAGE_EXPERIMENT] && window.experiments[COLOR_ADVISOR_SINGLE_PAGE_EXPERIMENT] === 'B';
}

export function isQuizComplete(params) {
  return mrApi.get('/api/quiz/isQuizComplete', { params });
}

export function createColorSolutionZendeskTicket() {
  return mrApi.post('/api/quiz/createColorSolutionZendeskTicket');
}

/**
 * Gets quiz flow by quiz flow key
 * @param {*} params
 * @returns {Promise}
 */
export function getQuizFlow(params) {
  return mrApi.get('/api/quiz/getQuizFlow', { params });
}

export function getCustomerAnswers() {
  return mrApi.get('/api/quiz/getCustomerAnswers');
}

export function getHairToneOptions(params) {
  return mrApi.get('/api/quiz/getHairToneOptions', { params });
}

/**
 * Saves quiz/survey answers to customerAnswersHistory
 * @param {*} params
 * @returns {Promise}
 */
export function persistCustomerAnswers(params) {
  return mrApi.post('/api/quiz/persistCustomerAnswers', params);
}

export function createZendeskTicket(params) {
  return mrApi.post('/api/quiz/createZendeskTicket', params);
}

export function dequeueZendeskTicket(params) {
  return mrApi.post('/api/quiz/dequeueZendeskTicket', params);
}

export function updateSubscriptionFrequency(params) {
  return mrApi.post('/api/quiz/updateSubscriptionFrequency', params);
}

export function saveNPS(params) {
  return mrApi.post('/api/quiz/saveNPS', params);
}

export function trackQuizEvent(params) {
  return mrApi.post('/api/quiz/trackQuizEvent', params);
}

export function saveRecommendations(params) {
  return mrApi.post('/api/quiz/saveRecommendations', params);
}

export function assignCustomerPromo(params) {
  return mrApi.post('/api/customer/assignCustomerPromo', params);
}

export function isAnyAnswerRecordedForQuiz(params) {
  return mrApi.get('/api/quiz/isAnyAnswerRecordedForQuiz', { params });
}
